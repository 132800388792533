<script setup>
import Layout from "../../layouts/main.vue";
import { useRoute, useRouter } from "vue-router";
import mushroom from "cem-primary-api";
import DataForm from "./dataFormListDownload";
import { onMounted, reactive, ref } from "vue";
import MethodService from "../../service/MethodService";
// ngôn ngữ -> bắt buộc
import en from "element-plus/lib/locale/lang/en";
import vi from "element-plus/lib/locale/lang/vi";

const tableRules = reactive(MethodService.copyObject(DataForm.tableRules));
// lấy ngôn ngữ từ pinia store
import { langWeb } from "../../stores/lang";
const lang_web = langWeb();
const loading = ref(true); // loading bảng
const loadingBtn = ref(false); // loading button
const dialogVisibleError = ref(false);
const contentError = ref(""); // thông tin chi tiết lỗi
const nameProbe = ref(""); // tên trạm bị lỗi
const dataTables = reactive({ value: [] }); // data bảng
const pageSizeDefault = ref(20);
const route = useRoute();
const router = useRouter();
const roles = ref([]);

const getListDownload = async () => {
  let dataFilter = {
    filters: tableRules.filters,
    fields: tableRules.fields,
    limit: tableRules.limit,
    offset: tableRules.offset,
    sort: tableRules.sort,
  };
  router
    .replace({
      name: "ListDownload",
      query: {
        limit: tableRules.limit,
        page: tableRules.page,
        sort: tableRules.sort,
        filters: tableRules.filters,
        showFormSearch: tableRules.showFormSearch,
      },
    })
    .catch(() => {});
  try {
    const response = await mushroom.request_download_dashboard.listAsync(
      dataFilter
    );
    const res = await changeData(response.result);
    dataTables.value = res;
    console.log("dataTables.value", dataTables.value);
    tableRules.total = response.meta.total;
    tableRules.page = response.meta.offset / response.meta.limit + 1;
  } catch (e) {
    console.error("Có lỗi: %o", e);
    MethodService.showError(e.code);
  } finally {
    loading.value = false;
  }
};

const changeData = async (data) => {
  data.forEach(async (item) => {
    item.name_probe = ref("");
    try {
      const response = await mushroom.probe.findByIdAsync(
        {
          id: item.probe_id,
        },
        { enabledCache: false }
      );
      if (response.result.columns.name)
        item.name_probe.value = response.result.columns.name;
    } catch (e) {
      console.error("Có lỗi: %o", e);
    }
  });
  return data;
};

// phân trang
const fn_tableSizeChange = (limit) => {
  tableRules.limit = limit;
  pageSizeDefault.value = limit;
  fn_tableChangeOffset(tableRules.page);
};
const fn_tableCurentChange = (page) => {
  fn_tableChangeOffset(page);
};
const fn_tablePrevClick = (page) => {
  fn_tableChangeOffset(page - 1);
};
const fn_tableNextClick = (page) => {
  fn_tableChangeOffset(page + 1);
};
const fn_tableChangeOffset = (page) => {
  tableRules.page = page;
  tableRules.offset = (tableRules.page - 1) * pageSizeDefault.value;
  getListDownload();
};
const fn_tableSortChange = (column) => {
  column.order == "ascending"
    ? (tableRules.sort = column.prop)
    : column.order == "descending"
    ? (tableRules.sort = "-" + column.prop)
    : (tableRules.sort = "");
  loading.value = true;
  getListDownload();
};

const fn_hashUrl = (routeQuery, table_rules) => {
  routeQuery = route.query; // không phải router
  table_rules = tableRules;
  MethodService.hashUrl(routeQuery, table_rules);
  pageSizeDefault.value = tableRules.limit;
};

const fn_handle = async (type, scope, row) => {
  if (type == "download") {
    loadingBtn.value = true;
    console.log("bấm download dữ liệu", row.id);
    try {
      await MethodService.downloadFileDashboard(row.id, "dashboard_data.xlsx");
    } catch (e) {
      console.log(e);
    } finally {
      loadingBtn.value = false;
    }
  }
  if (type == "error") {
    console.log("bấm show error", row.id);
    dialogVisibleError.value = true;
    nameProbe.value = row.name_probe;
    contentError.value = row.note;
  }
};

onMounted(async () => {
  fn_hashUrl();
  await getListDownload();
  await mushroom.$auth.meAsync().then((response) => {
    roles.value = response.result.roles;
  });
});
</script>

<template>
  <Layout>
    <div class="card">
      <div class="card-header" style=" display: flex, justify: space-between ">
        <h3 class="text-uppercase mb-0">{{ $t("download_list") }}</h3>
      </div>
      <div class="card-body">
        <el-config-provider :locale="lang_web.showLang == 'vi' ? vi : en">
          <div class="mb-3" style="float: right">
            <el-pagination
              small
              v-model:currentPage="tableRules.page"
              v-model:page-size="pageSizeDefault"
              :page-sizes="tableRules.lengthMenu"
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="tableRules.total"
              @size-change="fn_tableSizeChange"
              @current-change="fn_tableCurentChange"
              @prev-click="fn_tablePrevClick"
              @next-click="fn_tableNextClick"
            />
          </div>
          <el-table
            size="small"
            :data="dataTables.value"
            style="width: 100%"
            min-height="550"
            v-loading="loading"
            :empty-text="$t('t-no-data')"
            @sort-change="fn_tableSortChange"
            :default-sort="{
              prop: tableRules.defaultSort[0],
              order: tableRules.defaultSort[1],
            }"
          >
            <el-table-column
              prop=""
              :label="`${$t('t-stt')}`"
              width="70"
              align="center"
            >
              <template #default="scope">
                <div class="text-center">
                  {{ tableRules.offset + scope.$index + 1 }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="name_probe"
              :label="`${$t('t-probe-name')}`"
              min-width="100"
              align="start"
              :sortable="true"
            />
            <el-table-column
              prop="type"
              :label="`${$t('t-type-dashboard')}`"
              align="center"
              min-width="100"
            >
              <template #default="scope">
                <span
                  v-if="scope.row.type === 'main'"
                  class="badge rounded-pill badge-soft-dashboard"
                  >{{ $t("download_dashboard") }}</span
                >
                <span
                  v-if="scope.row.type === 'service'"
                  class="badge rounded-pill badge-soft-service"
                >
                  {{ $t("download_service") }}
                </span>
                <span
                  v-if="scope.row.type === 'customer'"
                  class="badge rounded-pill badge-soft-customer"
                >
                  {{ $t("download_customer") }}
                </span>
                <span
                  v-if="scope.row.type === 'timespan'"
                  class="badge rounded-pill badge-soft-timespan"
                >
                  {{ $t("download_timespan") }}
                </span>
                <span
                  v-if="scope.row.type === 'service_health'"
                  class="badge rounded-pill badge-soft-service-health"
                >
                  {{ $t("download_service_health") }}
                </span>
                <span
                  v-if="scope.row.type === 'uc'"
                  class="badge rounded-pill badge-soft-uc"
                >
                  {{ $t("download_uc") }}
                </span>
                <span
                  v-if="scope.row.type === 'tablescreen'"
                  class="badge rounded-pill badge-soft-tablescreen"
                >
                  {{ $t("download_tablescreen") }}
                </span>
                <span
                  v-if="scope.row.type === 'dynamic'"
                  class="badge rounded-pill badge-soft-dynamic"
                >
                  {{ $t("download_dynamic") }}
                </span>
                <span
                  v-if="scope.row.type === 'session'"
                  class="badge rounded-pill badge-soft-session"
                >
                  {{ $t("download_session") }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="created_time"
              :label="`${$t('t-time-created-download')}`"
              width="210"
              align="center"
              :sortable="true"
            >
              <template #default="scope">
                <div class="text-center">
                  {{ MethodService.formatDate(scope.row.created_time) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="completed_time"
              :label="`${$t('t-time-completed-download')}`"
              width="210"
              align="center"
              :sortable="true"
            >
              <template #default="scope">
                <div class="text-center" v-if="scope.row.completed_time">
                  {{ MethodService.formatDate(scope.row.completed_time) }}
                </div>
                <div class="text-center" v-else>-</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="status"
              :label="`${$t('t-status-download')}`"
              align="center"
              width="100"
            >
              <template #default="scope">
                <span
                  v-if="scope.row.status === 'new'"
                  class="badge badge-soft-info"
                  >{{ $t("download_new") }}</span
                >
                <span
                  v-if="scope.row.status === 'sent'"
                  class="badge badge-soft-secondary"
                  >{{ $t("download_sent") }}</span
                >
                <span
                  v-if="scope.row.status === 'processing'"
                  class="badge badge-soft-warning"
                  >{{ $t("download_processing") }}</span
                >
                <span
                  v-if="scope.row.status === 'error'"
                  class="badge badge-soft-danger"
                  >{{ $t("download_error") }}</span
                >
                <span
                  v-if="scope.row.status === 'completed'"
                  class="badge badge-soft-success"
                  >{{ $t("download_completed") }}</span
                >
              </template>
            </el-table-column>

            <el-table-column
              :label="`${$t('t-action')}`"
              align="center"
              width="100px"
            >
              <template #default="scope">
                <div class="btn-group-thao-tac">
                  <el-tooltip
                    class="box-item"
                    effect="light"
                    :content="$t('action_download')"
                    placement="left"
                    v-if="scope.row.status == 'completed'"
                  >
                    <button
                      type="button"
                      class="btn btn-soft-secondary waves-effect waves-light btn-sm"
                      @click="fn_handle('download', scope.$index, scope.row)"
                      :loading="loadingBtn"
                    >
                      <i class="ri-download-cloud-2-line"></i>
                    </button>
                  </el-tooltip>
                  <el-tooltip
                    class="box-item"
                    effect="light"
                    :content="$t('action_show_error')"
                    placement="left"
                    v-if="scope.row.status == 'error'"
                  >
                    <button
                      type="button"
                      class="btn btn-soft-danger waves-effect waves-light btn-sm"
                      @click="fn_handle('error', scope.$index, scope.row)"
                    >
                      <i class="ri-eye-line"></i>
                    </button>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="mt-3" style="float: right">
            <el-pagination
              small
              v-model:currentPage="tableRules.page"
              v-model:page-size="pageSizeDefault"
              :page-sizes="tableRules.lengthMenu"
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="tableRules.total"
              @size-change="fn_tableSizeChange"
              @current-change="fn_tableCurentChange"
              @prev-click="fn_tablePrevClick"
              @next-click="fn_tableNextClick"
            />
          </div>
        </el-config-provider>
        <!-- ${nameProbe}` -->
        <el-dialog
          v-model="dialogVisibleError"
          :title="$t('t_detail_error') + ' ' + nameProbe"
          width="50%"
          :close-on-click-modal="false"
        >
          <span class="error-content">{{ contentError }}</span>
        </el-dialog>
      </div>
    </div>
  </Layout>
</template>
<style scoped>
:deep .el-dialog__body {
  text-align: center;
}
.error-content {
  text-align: start;
  display: block;
}
:deep .el-dialog__header {
  border-bottom: thin solid #d4d6db;
  margin-right: 0;
}
</style>
