let model = {};

// dữ liệu form
model.dataForm = {
  id: undefined, // optional - Mã tự sinh
  user_id: undefined, // required, ref: user - Id của user
  probe_id: undefined, // required, ref: probe - Id của probe
  type: undefined, // required, enum(main, service, customer, timespan, service_health, dynamic) - Loại dashboard
  start_date: undefined, // required - Chọn ngày bắt đầu của dữ liệu
  stop_date: undefined, // required - Chọn ngày kết thúc của dữ liệu
  service_id: undefined, // optional, ref: service - Id của service nếu type là service
  uc_id: undefined, // optional, ref: usecase - Mã usecase nếu type là uc
  table_screen_id: undefined, // optional, ref: table_screen - Mã table screen
  service_health_id: undefined, // optional, ref: service_health - Id của service nếu type là service_health
  dynamic_dashboard_id: undefined, // optional, ref: dynamic_dashboard - Id của service nếu type là dynamic
  customer: undefined, // optional - IP khách hàng nếu dashboard là customer
  status: undefined, // required, enum(new, sent, processing, error, completed) - Trạng thái
  created_time: undefined, // required - Thời điểm tạo (yêu cầu tải file)
  sent_time: undefined, // optional - Thời điểm gửi yêu cầu
  processing_time: undefined, // optional - Thời điểm đang xử lý yêu cầu
  completed_time: undefined, // optional - Thời điểm hoàn thành
  note: undefined, // optional - Ghi chú
};

model.tableRules = {
  lengthMenu: [10, 20, 50, 100],
  allowPaging: true,
  allowSorting: true,
  allowSelect: false,
  showFormSearch: false,
  showUrl: true,
  total: 0,
  page: 1,
  limit: 20,
  offset: 0,
  sort: "-created_time",
  defaultSort: ["-created_time", "descending"],
  fields:
    "id,user_id,probe_id,type,start_date,stop_date,service_id,uc_id,table_screen_id,service_health_id,dynamic_dashboard_id,customer,data,status,created_time,sent_time,processing_time,completed_time,note",
  filters: "",
  dataSearch: {
    value: {
      account: "",
      roles: "",
      disabled: "",
    },
    operator: {
      account: ":regex_i:",
      roles: "",
      disabled: "",
    },
  },
};

export default model;
